import env from '../.env.js';
import { getLinkTo } from '../commons/route';
import { ApisConfigure } from '../datasource/index';
import UserCtxInstance from '../store/userContext';

const apiConfigure = (accessToken: string | null, aplicacaoLogged: string) => {
  const url = env.BASE_URL || window.location.origin.replace(/:[^\\/].*/, '');
  const port = env.BASE_PORT !== '80' ? env.BASE_PORT : '';

  ApisConfigure.configureConsumer(url, port);

  if (accessToken) {
    ApisConfigure.setGlobalAuthorizationToken(accessToken);
    ApisConfigure.setGlobalApplication(aplicacaoLogged);

    ApisConfigure.axiosInstances().forEach((api) => {
      api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            UserCtxInstance.logout();
            window.location.href = getLinkTo('mgt');
          }

          return Promise.reject(error);
        },
      );
    });
  }
};

export default apiConfigure;
