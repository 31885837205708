import i18next from 'i18next';
import { action, computed, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import { localStorageDef } from '../commons/consts';
import { historyPush } from '../commons/route';
import Storage from '../commons/storage';

type AccessUserInfo = { iat: number; uuid: string; sub: string; nome: string; aplicacaoLogged: string };

export class Ctx {
  constructor() {
    //Modifica classe pra ser observável
    makeObservable(this);

    this.loadUser();
    this.loadApplication();
  }

  @observable usuario = {} as AccessUserInfo;
  @observable token = null as string | null;
  @observable expiraEm = null as number | null;
  @observable aplicacao = {} as { uuid: string; nome?: string };

  changeLanguage(lng?: string): Promise<Function> {
    return i18next.changeLanguage(lng);
  }

  //Realiza autenticação do usuário
  @action
  login(usuario: any, token: string | null, expiraEm: number | null): void {
    this.saveUser(usuario, token, expiraEm);
  }

  @action
  logout() {
    this.saveUser({}, null, null);

    historyPush('/');
  }

  //Retorna se usuário esta logado
  @computed
  get isAuth(): boolean {
    return !!this.token;
  }

  @action loadUser() {
    this.usuario = Storage.getItem(localStorageDef.usuarioContextKey, {});
    this.token = Storage.getItem(localStorageDef.tokenKey);
    this.expiraEm = Storage.getItem(localStorageDef.tokenKey);
  }

  @action saveUser(usuario: any, token: string | null, expiraEm: number | null) {
    Storage.setItem(localStorageDef.usuarioContextKey, usuario);
    Storage.setItem(localStorageDef.tokenKey, token);
    Storage.setItem(localStorageDef.expiraEm, expiraEm);

    this.usuario = usuario;
    this.token = token;
    this.expiraEm = expiraEm;
  }

  @action loadApplication() {
    this.aplicacao = Storage.getItem(localStorageDef.aplicacaoContextKey, {});
  }

  @action saveApplication(aplicacao: { uuid: string; nome?: string }) {
    Storage.setItem(localStorageDef.aplicacaoContextKey, aplicacao);

    this.aplicacao = aplicacao;
  }
}

const UserCtxInstance = new Ctx();
export default UserCtxInstance;

export const UserContext = createContext<Ctx>({} as Ctx);
export const UserProvider = UserContext.Provider;
export const useUserStore = (): Ctx => useContext(UserContext);
