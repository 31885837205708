const iamConfig = {
  PRODCTION_MODE: process.env.NODE_ENV === 'production',
  // Default Login Scope
  DEFAULT_SCOPE: 'iam_all',
  // Applicação Principal
  MAIN_APP_IAM: 'iam',
  MAIN_APP_IAM_ID: 'f6f59620-7cba-46ec-a5ff-a2a85696a81a',
  // Aplicação de gestão do IAM SSO
  MAIN_APP_IAM_MGT: 'iam_mgt',
  MAIN_APP_IAM_MGT_ID: '3269c573-f719-4cc0-ad30-26f0ef599d77',
};

export default iamConfig;
